import { apolloClient } from "@/plugins/vue-apollo.js";

const sentOtp = (_variables) => {
  // , limit, skip
  let responseFromApi;
  let variables = _variables;
  responseFromApi = apolloClient
    .mutate({
      mutation: require("@/user/api/mutations/forgetPassword/sent_otp.graphql"),
      variables: variables,
    })
    .catch(error => {
      return error;
    });
  return responseFromApi;
};
export { sentOtp };
